import IIconType from '../IIconType';

/**
 * @param {IIconType} props - The props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineFilterAlt: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-outline-filter"
    >
      <path
        d="M18.8242 1.32422C19.1055 1.88672 19 2.55469 18.6133 3.01172L12.7773 10.1484V14.7891C12.7773 15.5273 12.1797 16.125 11.4414 16.125C11.1602 16.125 10.8789 16.0547 10.6328 15.8789L7.75 13.6641C7.39844 13.3828 7.1875 12.9961 7.1875 12.5391V10.1484L1.35156 3.01172C0.964844 2.55469 0.859375 1.88672 1.14062 1.32422C1.38672 0.761719 1.98438 0.375 2.61719 0.375H17.3477C17.9805 0.375 18.5781 0.761719 18.8242 1.32422ZM11.3711 9.23438L17.207 2.09766H2.75781L8.59375 9.23438C8.76953 9.44531 8.875 9.69141 8.875 9.97266V12.3633L11.125 14.1211V9.9375C11.125 9.65625 11.1953 9.41016 11.3711 9.23438Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineFilterAlt;
