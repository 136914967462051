import React from 'react';
import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconFillSortUp: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 6"
      fill="none"
      data-testid="icon-fill-sort-up"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.856671 6C0.106671 6 -0.299579 5.09375 0.262921 4.53125L4.38792 0.28125C4.54417 0.09375 4.79417 0 5.01292 0C5.23167 0 5.45042 0.09375 5.60667 0.28125L9.73167 4.53125C10.2942 5.09375 9.88792 6 9.13792 6H0.856671Z"
        fill={color}
      />
    </svg>
  );
};

export default IconFillSortUp;
