var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"tgVEGytZtwPm1bNjKTlUA"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/lost";

/*
 * This file configures the initialization of Sentry on the client.
 * The config you add here will be used whenever a users loads a page in their browser.
 * https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://e7db5744b7574e0289ee701f88c346a0@o255418.ingest.sentry.io/4505563233320960',
  // Disable Tracing
  enableTracing: false,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  /** Set the Environment */
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  /** Enable Sentry Conditionally based on the Node Environment */
  enabled: process.env.NODE_ENV !== 'development',
});
