import IconFillCheckbox from './fill/IconFillCheckbox';
import IconFillInfo from './fill/IconFillInfo';
import IconFillSearch from './fill/IconFillSearch';
import IconFillSortDefault from './fill/IconFillSortDefault';
import IconFillSortDown from './fill/IconFillSortDown';
import IconFillSortUp from './fill/IconFillSortUp';
import IconFillSwitch from './fill/IconFillSwitch';
import IconFillUpload from './fill/IconFillUpload';
import IllustrationAdoptThin from './illustration/IllustrationAdoptThin';
import IllustrationAmbulance from './illustration/IllustrationAmbulance';
import IllustrationBandageHeart from './illustration/IllustrationBandageHeart';
import IllustrationBinocular from './illustration/IllustrationBinocular';
import IllustrationBrokenHeart from './illustration/IllustrationBrokenHeart';
import IllustrationCalendar from './illustration/IllustrationCalendar';
import IllustrationCamera from './illustration/IllustrationCamera';
import IllustrationCareThin from './illustration/IllustrationCareThin';
import IllustrationCat from './illustration/IllustrationCat';
import IllustrationChatBubbles from './illustration/IllustrationChatBubbles';
import IllustrationCollar from './illustration/IllustrationCollar';
import IllustrationCommunity from './illustration/IllustrationCommunity';
import IllustrationDog from './illustration/IllustrationDog';
import IllustrationGerm from './illustration/IllustrationGerm';
import IllustrationHeart from './illustration/IllustrationHeart';
import IllustrationHouse from './illustration/IllustrationHouse';
import IllustrationLightbulb from './illustration/IllustrationLightbulb';
import IllustrationLostThin from './illustration/IllustrationLostThin';
import IllustrationMap from './illustration/IllustrationMap';
import IllustrationMegaphone from './illustration/IllustrationMegaphone';
import IllustrationMicrochip from './illustration/IllustrationMicrochip';
import IllustrationMoneyJar from './illustration/IllustrationMoneyJar';
import IllustrationPartyPopperFilled from './illustration/IllustrationPartyPopperFilled';
import IllustrationPartyPopperOutlined from './illustration/IllustrationPartyPopperOutlined';
import IllustrationPaw from './illustration/IllustrationPaw';
import IllustrationPawPrint from './illustration/IllustrationPawPrint';
import IllustrationPetFood from './illustration/IllustrationPetFood';
import IllustrationPets from './illustration/IllustrationPets';
import IllustrationPetStore from './illustration/IllustrationPetStore';
import IllustrationPoster from './illustration/IllustrationPoster';
import IllustrationTags from './illustration/IllustrationTags';
import IllustrationVaccinatedPaw from './illustration/IllustrationVaccinatedPaw';
import IconOutlineAlert from './outline/IconOutlineAlert';
import IconOutlineArrowDown from './outline/IconOutlineArrowDown';
import IconOutlineArrowLeft from './outline/IconOutlineArrowLeft';
import IconOutlineArrowRight from './outline/IconOutlineArrowRight';
import IconOutlineArrowUp from './outline/IconOutlineArrowUp';
import IconOutlineArrowUpCircle from './outline/IconOutlineArrowUpCircle';
import IconOutlineArrowUpToLine from './outline/IconOutlineArrowUpToLine';
import IconOutlineBell from './outline/IconOutlineBell';
import IconOutlineBowtie from './outline/IconOutlineBowtie';
import IconOutlineCalendar from './outline/IconOutlineCalendar';
import IconOutlineCamera from './outline/IconOutlineCamera';
import IconOutlineCat from './outline/IconOutlineCat';
import IconOutlineCheckCircle from './outline/IconOutlineCheckCircle';
import IconOutlineChevronDown from './outline/IconOutlineChevronDown';
import IconOutlineChevronLeft from './outline/IconOutlineChevronLeft';
import IconOutlineChevronLeftCircled from './outline/IconOutlineChevronLeftCircled';
import IconOutlineChevronRight from './outline/IconOutlineChevronRight';
import IconOutlineChevronRightCircled from './outline/IconOutlineChevronRightCircled';
import IconOutlineChevronUp from './outline/IconOutlineChevronUp';
import IconOutlineCircleSpinner from './outline/IconOutlineCircleSpinner';
import IconOutlineClose from './outline/IconOutlineClose';
import IconOutlineCloseCircle from './outline/IconOutlineCloseCircle';
import IconOutlineCrosshairsLight from './outline/IconOutlineCrosshairsLight';
import IconOutlineCrown from './outline/IconOutlineCrown';
import IconOutlineDog from './outline/IconOutlineDog';
import IconOutlineDownload from './outline/IconOutlineDownload';
import IconOutlineEnvelope from './outline/IconOutlineEnvelope';
import IconOutlineEnvelopeLight from './outline/IconOutlineEnvelopeLight';
import IconOutlineExclamationCircle from './outline/IconOutlineExclamationCircle';
import IconOutlineExclamationTriangle from './outline/IconOutlineExclamationTriangle';
import IconOutlineEye from './outline/IconOutlineEye';
import IconOutlineEyeSlash from './outline/IconOutlineEyeSlash';
import IconOutlineFacebook from './outline/IconOutlineFacebook';
import IconOutlineFile from './outline/IconOutlineFile';
import IconOutlineFilter from './outline/IconOutlineFilter';
import IconOutlineFilterAlt from './outline/IconOutlineFilterAlt';
import IconOutlineFlag from './outline/IconOutlineFlag';
import IconOutlineGlobe from './outline/IconOutlineGlobe';
import IconOutlineGlobeLarge from './outline/IconOutlineGlobeLarge';
import IconOutlineHome from './outline/IconOutlineHome';
import IconOutlineImage from './outline/IconOutlineImage';
import IconOutlineInfo from './outline/IconOutlineInfo';
import IconOutlineInstagram from './outline/IconOutlineInstagram';
import IconOutlineLightBulb from './outline/IconOutlineLightBulb';
import IconOutlineLink from './outline/IconOutlineLink';
import IconOutlineLinkedIn from './outline/IconOutlineLinkedIn';
import IconOutlineList from './outline/IconOutlineList';
import IconOutlineMap from './outline/IconOutlineMap';
import IconOutlineMaximize from './outline/IconOutlineMaximize';
import IconOutlineMegaPhone from './outline/IconOutlineMegaphone';
import IconOutlineMenu from './outline/IconOutlineMenu';
import IconOutlineMessage from './outline/IconOutlineMessage';
import IconOutlineMinimize from './outline/IconOutlineMinimize';
import IconOutlineMinus from './outline/IconOutlineMinus';
import IconOutlineMoreHorizontal from './outline/IconOutlineMoreHorizontal';
import IconOutlineMoreVertical from './outline/IconOutlineMoreVertical';
import IconOutlineNavigationArrow from './outline/IconOutlineNavigationArrow';
import IconOutlineNextdoor from './outline/IconOutlineNextdoor';
import IconOutlinePaw from './outline/IconOutlinePaw';
import IconOutlinePhone from './outline/IconOutlinePhone';
import IconOutlinePin from './outline/IconOutlinePin';
import IconOutlinePlayCircle from './outline/IconOutlinePlayCircle';
import IconOutlinePlus from './outline/IconOutlinePlus';
import IconOutlinePrinter from './outline/IconOutlinePrinter';
import IconOutlineQuestionCircle from './outline/IconOutlineQuestionCircle';
import IconOutlineQuote from './outline/IconOutlineQuote';
import IconOutlineReupload from './outline/IconOutlineReupload';
import IconOutlineRotate from './outline/IconOutlineRotate';
import IconOutlineSearch from './outline/IconOutlineSearch';
import IconOutlineSignPost from './outline/IconOutlineSignPost';
import IconOutlineStar from './outline/IconOutlineStar';
import IconOutlineTrash from './outline/IconOutlineTrash';
import IconOutlineTwitter from './outline/IconOutlineTwitter';
import IconOutlineUpload from './outline/IconOutlineUpload';
import IconOutlineUserCircle from './outline/IconOutlineUserCircle';
import IconOutlineYoutube from './outline/IconOutlineYoutube';
import IconOutlineFullArrowLeft from './outline/IconOutlineFullArrowLeft';
import IconOutlineFullArrowRight from './outline/IconOutlineFullArrowRight';

/**
 * Icon List List of all available icons available in the project
 *
 * @constant
 */
const IconList = {
  alert: IconOutlineAlert,
  arrowDown: IconOutlineArrowDown,
  arrowLeft: IconOutlineArrowLeft,
  arrowRight: IconOutlineArrowRight,
  arrowUp: IconOutlineArrowUp,
  arrowUpCircle: IconOutlineArrowUpCircle,
  arrowUpToLine: IconOutlineArrowUpToLine,
  bell: IconOutlineBell,
  bowtie: IconOutlineBowtie,
  calendar: IconOutlineCalendar,
  camera: IconOutlineCamera,
  cat: IconOutlineCat,
  checkboxFill: IconFillCheckbox,
  checkCircle: IconOutlineCheckCircle,
  chevronDown: IconOutlineChevronDown,
  chevronUp: IconOutlineChevronUp,
  chevronRight: IconOutlineChevronRight,
  chevronLeft: IconOutlineChevronLeft,
  chevronLeftCircled: IconOutlineChevronLeftCircled,
  chevronRightCircled: IconOutlineChevronRightCircled,
  circleSpinner: IconOutlineCircleSpinner,
  close: IconOutlineClose,
  closeCircle: IconOutlineCloseCircle,
  crown: IconOutlineCrown,
  crosshairsLight: IconOutlineCrosshairsLight,
  dog: IconOutlineDog,
  download: IconOutlineDownload,
  envelope: IconOutlineEnvelope,
  envelopeLight: IconOutlineEnvelopeLight,
  exclamationCircle: IconOutlineExclamationCircle,
  exclamationTriangle: IconOutlineExclamationTriangle,
  eye: IconOutlineEye,
  eyeSlash: IconOutlineEyeSlash,
  facebook: IconOutlineFacebook,
  file: IconOutlineFile,
  flag: IconOutlineFlag,
  fullArrowLeft: IconOutlineFullArrowLeft,
  fullArrowRight: IconOutlineFullArrowRight,
  filter: IconOutlineFilter,
  filterAlt: IconOutlineFilterAlt,
  globe: IconOutlineGlobe,
  globeLarge: IconOutlineGlobeLarge,
  home: IconOutlineHome,
  image: IconOutlineImage,
  infoFill: IconFillInfo,
  infoOutline: IconOutlineInfo,
  instagram: IconOutlineInstagram,
  lightBulb: IconOutlineLightBulb,
  link: IconOutlineLink,
  linkedIn: IconOutlineLinkedIn,
  list: IconOutlineList,
  map: IconOutlineMap,
  maximize: IconOutlineMaximize,
  megaPhone: IconOutlineMegaPhone,
  minimize: IconOutlineMinimize,
  menu: IconOutlineMenu,
  message: IconOutlineMessage,
  minus: IconOutlineMinus,
  moreVertical: IconOutlineMoreVertical,
  moreHorizontal: IconOutlineMoreHorizontal,
  navigationArrow: IconOutlineNavigationArrow,
  nextdoor: IconOutlineNextdoor,
  paw: IconOutlinePaw,
  phone: IconOutlinePhone,
  pin: IconOutlinePin,
  printer: IconOutlinePrinter,
  questionCircle: IconOutlineQuestionCircle,
  quote: IconOutlineQuote,
  playCircle: IconOutlinePlayCircle,
  plus: IconOutlinePlus,
  reupload: IconOutlineReupload,
  rotate: IconOutlineRotate,
  search: IconOutlineSearch,
  searchFill: IconFillSearch,
  sortDefaultFill: IconFillSortDefault,
  sortDown: IconFillSortDown,
  sortUp: IconFillSortUp,
  signPost: IconOutlineSignPost,
  star: IconOutlineStar,
  switch: IconFillSwitch,
  trash: IconOutlineTrash,
  twitter: IconOutlineTwitter,
  userCircle: IconOutlineUserCircle,
  uploadFill: IconFillUpload,
  uploadOutline: IconOutlineUpload,
  youtube: IconOutlineYoutube,
  // Illustrations
  illustrationAdoptThin: IllustrationAdoptThin,
  illustrationAmbulance: IllustrationAmbulance,
  illustrationBandageHeart: IllustrationBandageHeart,
  illustrationBinocular: IllustrationBinocular,
  illustrationBrokenHeart: IllustrationBrokenHeart,
  illustrationCalendar: IllustrationCalendar,
  illustrationCamera: IllustrationCamera,
  illustrationCareThin: IllustrationCareThin,
  illustrationCat: IllustrationCat,
  illustrationChatBubbles: IllustrationChatBubbles,
  illustrationCollar: IllustrationCollar,
  illustrationCommunity: IllustrationCommunity,
  illustrationDog: IllustrationDog,
  illustrationGerm: IllustrationGerm,
  illustrationHeart: IllustrationHeart,
  illustrationHouse: IllustrationHouse,
  illustrationLightbulb: IllustrationLightbulb,
  illustrationLostThin: IllustrationLostThin,
  illustrationMap: IllustrationMap,
  illustrationMegaphone: IllustrationMegaphone,
  illustrationMicrochip: IllustrationMicrochip,
  illustrationMoneyJar: IllustrationMoneyJar,
  illustrationPartyPopperOutlined: IllustrationPartyPopperOutlined,
  illustrationPartyPopperFilled: IllustrationPartyPopperFilled,
  illustrationPaw: IllustrationPaw,
  illustrationPawPrint: IllustrationPawPrint,
  illustrationPetFood: IllustrationPetFood,
  illustrationPets: IllustrationPets,
  illustrationPetStore: IllustrationPetStore,
  illustrationPoster: IllustrationPoster,
  illustrationTags: IllustrationTags,
  illustrationVaccinatedPaw: IllustrationVaccinatedPaw,
};

export default IconList;
