import React from 'react';
import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconFillSortDefault: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 14"
      fill="none"
      data-testid="icon-fill-sort-default"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.856671 6C0.106671 6 -0.299579 5.09375 0.262921 4.53125L4.38792 0.28125C4.54417 0.09375 4.79417 0 5.01292 0C5.23167 0 5.45042 0.09375 5.60667 0.28125L9.73167 4.53125C10.2942 5.09375 9.88792 6 9.13792 6H0.856671ZM9.13792 8C9.88792 8 10.2942 8.9375 9.73167 9.5L5.60667 13.75C5.45042 13.9375 5.23167 14 5.01292 14C4.76292 14 4.54417 13.9375 4.38792 13.75L0.262921 9.5C-0.299579 8.9375 0.106671 8 0.856671 8H9.13792Z"
        fill={color}
      />
    </svg>
  );
};

export default IconFillSortDefault;
